.level-pink, .theme-pink  {
  --main-950: #4C0B1A;
  --main-900: #7D1E34;
  --main-800: #961E3A;
  --main-700: #B52146;
  --main-600: #D1315D;
  --main-500: #E35180;
  --main-400: #ED79A2;
  --main-300: #F5ACC8;
  --main-200: #F9D1E1;
  --main-100: #FBE8F0;
  --main-50: #FCF3F6;

  --level-primary-950: #4C0B1A;
  --level-primary-900: #7D1E34;
  --level-primary-800: #961E3A;
  --level-primary-700: #B52146;
  --level-primary-600: #D1315D;
  --level-primary-500: #E35180;
  --level-primary-400: #ED79A2;
  --level-primary-300: #F5ACC8;
  --level-primary-200: #F9D1E1;
  --level-primary-100: #FBE8F0;
  --level-primary-50: #FCF3F6;

  --level-primary: #ED79A2;
}
