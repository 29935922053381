.level-green, .theme-green {
  --main-950: #16250E;
  --main-900: #2E4621;
  --main-800: #355123;
  --main-700: #3F6526;
  --main-600: #51832D;
  --main-500: #75B843;
  --main-400: #88C15A;
  --main-300: #A7D482;
  --main-200: #C9E6B0;
  --main-100: #E3F2D5;
  --main-50: #F3F9EC;

  --level-primary-950: #16250E;
  --level-primary-900: #2E4621;
  --level-primary-800: #355123;
  --level-primary-700: #3F6526;
  --level-primary-600: #51832D;
  --level-primary-500: #75B843;
  --level-primary-400: #88C15A;
  --level-primary-300: #A7D482;
  --level-primary-200: #C9E6B0;
  --level-primary-100: #E3F2D5;
  --level-primary-50: #F3F9EC;

  --level-primary: #75B843;
}
