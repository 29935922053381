//
// Google font - Poppins
//

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  font-style: normal;
  font-weight: 350;
  src: url("/assets/fonts/avenir/AvenirLTProBook.otf") format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/avenir/AvenirLTProRoman.otf") format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/avenir/AvenirLTProMedium.otf") format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  font-style: normal;
  font-weight: 800;
  src: url("/assets/fonts/avenir/AvenirLTProHeavy.otf") format('opentype');
}

@font-face {
  font-family: 'Avenir';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/avenir/AvenirLTProBlack.otf") format('opentype');
}

.user-side-font {
  font-family: Avenir, sans-serif;
}


.fs-xxsmall {
  font-size: 8px;
}

.fs-xsmall {
  font-size: 10px;
}

.fs-small {
  font-size: 12px;
}

.fs-normal {
  font-size: 14px;
}

.fs-large {
  font-size: 16px;
}

.fs-xlarge {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-title {
  font-size: 22px;
}

.fs-title-small {
  font-size: 24px;
}

.fs-title-medium {
  font-size: 30px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-26 {
  font-size: 28px;
}

.fs-36 {
  font-size: 36px;
}

.fs-40 {
  font-size: 40px;
}

.fw-350 {
  font-weight: 350;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}
