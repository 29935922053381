.level-blue, .theme-blue{
  --main-950: #13273E;
  --main-900: #1C3D5E;
  --main-800: #1C4870;
  --main-700: #1E5386;
  --main-600: #2368A6;
  --main-500: #3384C4;
  --main-400: #559ED7;
  --main-300: #92C0E7;
  --main-200: #C5DCF2;
  --main-100: #E5EEF9;
  --main-50: #F3F7FC;

  --level-primary-950: #13273E;
  --level-primary-900: #1C3D5E;
  --level-primary-800: #1C4870;
  --level-primary-700: #1E5386;
  --level-primary-600: #2368A6;
  --level-primary-500: #3384C4;
  --level-primary-400: #559ED7;
  --level-primary-300: #92C0E7;
  --level-primary-200: #C5DCF2;
  --level-primary-100: #E5EEF9;
  --level-primary-50: #F3F7FC;

  --level-primary: #559ED7;
}
