.level-red, .theme-red {
  --main-950: #490706;
  --main-900: #861816;
  --main-800: #A21412;
  --main-700: #C41411;
  --main-600: #E91C19;
  --main-500: #FB3B38;
  --main-400: #FF6967;
  --main-300: #FFA09F;
  --main-200: #FFC7C6;
  --main-100: #FFE0E0;
  --main-50: #FFF1F1;

  --level-primary-950: #490706;
  --level-primary-900: #861816;
  --level-primary-800: #A21412;
  --level-primary-700: #C41411;
  --level-primary-600: #E91C19;
  --level-primary-500: #FB3B38;
  --level-primary-400: #FF6967;
  --level-primary-300: #FFA09F;
  --level-primary-200: #FFC7C6;
  --level-primary-100: #FFE0E0;
  --level-primary-50: #FFF1F1;

  --level-primary: #E91C19;
}
