/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone{
  border:2px dashed var(--bs-border-color) !important;
}

ngx-dropzone-preview {
  max-width: none !important;
  justify-content: normal !important;
  min-height: 80px !important;
  background-image: none !important;
  width: 100%;
  img {
    opacity: 1 !important;
    max-height: 95px;
  }

  ngx-dropzone-label {
    position: unset !important;
  }
}


ng-stepper {
  overflow: unset!important;
}

modal-container + modal-container {

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #00000075;

}

$lineCounts:1,2,3,4,5;

@each $lineCount in $lineCounts {
  .max-line-#{$lineCount} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$lineCount};
    line-clamp: #{$lineCount};
    -webkit-box-orient: vertical;
  }
}

:root {
  --ck-z-default: 10555 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999) !important;
}

// Fix ckeditor dropdown arrow z-index
.ck-dropdown__button .ck-dropdown__arrow {
  z-index: 1 !important;
}
