.level-purple, .theme-purple  {
  --main-950: #480054;
  --main-900: #6D137C;
  --main-800: #831098;
  --main-700: #A111BF;
  --main-600: #B919E0;
  --main-500: #D339FC;
  --main-400: #E36FFF;
  --main-300: #ECA5FF;
  --main-200: #F3CCFF;
  --main-100: #F9E6FF;
  --main-50: #FDF3FF;

  --level-primary-950: #13273E;
  --level-primary-900: #6D137C;
  --level-primary-800: #831098;
  --level-primary-700: #B919E0;
  --level-primary-600: #B919E0;
  --level-primary-500: #D339FC;
  --level-primary-400: #E36FFF;
  --level-primary-300: #ECA5FF;
  --level-primary-200: #F3CCFF;
  --level-primary-100: #F9E6FF;
  --level-primary-50: #FDF3FF;

  --level-primary: #B919E0;
}
