@import "theme/blue";
@import "theme/default";
@import "theme/green";
@import "theme/orange";
@import "theme/pink";
@import "theme/purple";
@import "theme/red";

:root {
  --white: #fff;

  /*Brand*/
  --gold-950: #442404;
  --gold-900: #74460F;
  --gold-800: #89550A;
  --gold-700: #A66E02;
  --gold-600: #D19A00;
  --gold-500: #FFD200;
  --gold-400: #FFE10D;
  --gold-300: #FFF141;
  --gold-200: #FFFB86;
  --gold-100: #FFFFC1;
  --gold-50: #FFFFE7;

  --gray-950: #282828;
  --gray-900: #3D3D3D;
  --gray-800: #454545;
  --gray-700: #4F4F4F;
  --gray-600: #5D5D5D;
  --gray-500: #6D6D6D;
  --gray-400: #888888;
  --gray-300: #B0B0B0;
  --gray-200: #D1D1D1;
  --gray-100: #E7E7E7;
  --gray-50: #F6F6F6;

  --danger: #E6494B;

  /*Levels*/
  --pink-950: #4C0B1A;
  --pink-900: #7D1E34;
  --pink-800: #961E3A;
  --pink-700: #B52146;
  --pink-600: #D1315D;
  --pink-500: #E35180;
  --pink-400: #ED79A2;
  --pink-300: #F5ACC8;
  --pink-200: #F9D1E1;
  --pink-100: #FBE8F0;
  --pink-50: #FCF3F6;

  --green-950: #16250E;
  --green-900: #2E4621;
  --green-800: #355123;
  --green-700: #3F6526;
  --green-600: #51832D;
  --green-500: #75B843;
  --green-400: #88C15A;
  --green-300: #A7D482;
  --green-200: #C9E6B0;
  --green-100: #E3F2D5;
  --green-50: #F3F9EC;

  --orange-950: #452105;
  --orange-900: #80450E;
  --orange-800: #A0520C;
  --orange-700: #C96A05;
  --orange-600: #F58C03;
  --orange-500: #FFA70D;
  --orange-400: #FFBE34;
  --orange-300: #FFDA6E;
  --orange-200: #FFEBA7;
  --orange-100: #FFF7D3;
  --orange-50: #FFFBEC;

  --blue-950: #13273E;
  --blue-900: #1C3D5E;
  --blue-800: #1C4870;
  --blue-700: #1E5386;
  --blue-600: #2368A6;
  --blue-500: #3384C4;
  --blue-400: #559ED7;
  --blue-300: #92C0E7;
  --blue-200: #C5DCF2;
  --blue-100: #E5EEF9;
  --blue-50: #F3F7FC;

  --purple-950: #13273E;
  --purple-900: #6D137C;
  --purple-800: #831098;
  --purple-700: #B919E0;
  --purple-600: #B919E0;
  --purple-500: #D339FC;
  --purple-400: #E36FFF;
  --purple-300: #ECA5FF;
  --purple-200: #F3CCFF;
  --purple-100: #F9E6FF;
  --purple-50: #FDF3FF;

  --red-950: #490706;
  --red-900: #861816;
  --red-800: #A21412;
  --red-700: #C41411;
  --red-600: #E91C19;
  --red-500: #FB3B38;
  --red-400: #FF6967;
  --red-300: #FFA09F;
  --red-200: #FFC7C6;
  --red-100: #FFE0E0;
  --red-50: #FFF1F1;

  --brand-primary: var(--gold-500);
  --brand-secondary: var(--gray-950);
}

$colors: "gold", "gray", "pink", "green", "orange", "blue", "purple", "red";
$tones: 950, 900, 800, 700, 600, 500, 400, 300, 200, 100, 50;

@each $color in $colors {
  @each $tone in $tones {
    .color-#{$color}-#{$tone} {
      color: var(--#{$color}-#{$tone});
    }

    .bg-#{$color}-#{$tone} {
      background-color: var(--#{$color}-#{$tone});
    }
  }
}


/* Brand Colors */
@each $tone in $tones {
  .color-brand-primary-#{$tone} {
    color: var(--gold-#{$tone});
  }

  .bg-brand-primary-#{$tone} {
    background-color: var(--gold-#{$tone});
  }

  .border-brand-primary-#{$tone} {
    border-color: var(--gold-#{$tone});
  }

  .color-brand-secondary-#{$tone} {
    color: var(--gray-#{$tone});
  }

  .bg-brand-secondary-#{$tone} {
    background-color: var(--gray-#{$tone});
  }

  .border-brand-secondary-#{$tone} {
    border-color: var(--gray-#{$tone});
  }
}

.color-brand-primary {
  color: var(--brand-primary);
}

.bg-brand-primary {
  background-color: var(--brand-primary);
}

.border-brand-primary {
  border-color: var(--brand-primary);
}

.color-brand-secondary {
  color: var(--brand-secondary);
}

.bg-brand-secondary {
  background-color: var(--brand-secondary);
}

.border-brand-secondary {
  background-color: var(--brand-secondary);
}

/* Level Colors */
$colors: "pink", "green", "orange", "blue", "purple", "red";
@each $color in $colors {
  @each $tone in $tones {
    .color-level-#{$color}-#{$tone} {
      color: var(--#{$color}-#{$tone});
    }

    .bg-level-#{$color}-#{$tone} {
      background-color: var(--#{$color}-#{$tone});
    }

    .border-level-#{$color}-#{$tone} {
      border-color: var(--#{$color}-#{$tone});
    }

    .border-left-level-#{$color}-#{$tone} {
      border-left-color: var(--#{$color}-#{$tone});
    }

    .border-right-level-#{$color}-#{$tone} {
      border-right-color: var(--#{$color}-#{$tone});
    }

    .border-top-level-#{$color}-#{$tone} {
      border-top-color: var(--#{$color}-#{$tone});
    }

    .border-bottom-level-#{$color}-#{$tone} {
      border-bottom-color: var(--#{$color}-#{$tone});
    }

    .hover-bg-level-#{$color}-#{$tone} {
      &:hover {
        background-color: var(--#{$color}-#{$tone});
      }
    }
  }
}

:root {
  --linear-gold: var(--gold-500);
  //--linear-gold: linear-gradient(106deg, #FFD200 34.56%, #D19A00 144.54%);
  --linear-pembe: var(--linear-pink, linear-gradient(180deg, #ED79A2 35.58%, #D1315D 167.71%));
}

.color-black {
  color: #000;
}

.bg-linear-pink {
  background-color: var(--linear-pembe);
}

.color-danger {
  color: var(--danger);
}

.color-white {
  color: #fff;
}


$tones:950,900,800,700,600,500,400,300,200,100,50;

/* Brand Colors */
@each $tone in $tones {
  .color-level-primary-#{$tone} {
    color: var(--level-primary-#{$tone});
  }

  .bg-level-primary-#{$tone} {
    background-color: var(--level-primary-#{$tone});
  }
}

.color-level-primary {
  color: var(--level-primary);
}

.bg-level-primary {
  background-color: var(--level-primary);
}
